// Dependencies
const anime = require("animejs");
const flubber = require("flubber");

window.addEventListener("DOMContentLoaded", () => {

    const paths = [
        "M619.2,43.5C503.8,36.4,419,113.8,228,118.4C95,121.6,14.3,207.5,12.6,357c-1.8,155.6,83.9,222.1,202.1,234.2c259.1,26.4,293.6-102.3,439.1-106.6c151.7-4.5,215.5-106.8,169.8-221.7C794.1,188.5,806.6,55,619.2,43.5z", "M154.55,519c107.27,43.16,212.14-3.56,394.84,52.42c127.24,38.99,230.87-17.01,279.69-158.33    c50.8-147.06-9.46-237.28-117.84-286.05C473.73,20.15,400.3,131.4,260.91,89.5C115.55,45.82,22.73,122.81,29.81,246.25C34.39,326.12-19.66,448.9,154.55,519z"
    ];

    let path = document.querySelector("#error-404 #morphingMask");
    let interpolator = flubber.interpolate(paths[0], paths[1]);
    let progress = { interpolation: 0 };

    anime({
        targets: progress,
        interpolation: 10000,
        easing: "easeInOutQuad",
        duration: 20000,
        loop: true,
        round: 10,
        direction: "alternate",
        update: () => path.setAttribute("d", interpolator(progress.interpolation / 10000))
    });

});